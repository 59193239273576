import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '@components';

const StyledProjectContainer = styled.main`
  max-width: 1000px;
`;
const StyledProjectHeader = styled.header`
  margin-bottom: 50px;
  .tag {
    margin-right: 10px;
  }
`;
const StyledProjectContent = styled.div`
  margin-bottom: 100px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2em 0 1em;
  }

  p {
    margin: 1em 0;
    line-height: 1.5;
    color: ${props => props.theme.lightslate};
  }

  a {
    ${({ theme }) => theme.mixins.inlineLink};
  }

  code {
    background-color: ${({ theme }) => theme.lightestnavy};
    color: ${({ theme }) => theme.lightestslate};
    border-radius: var(--border-radius);
    font-size: var(--fz-sm);
    padding: 0.2em 0.4em;
  }

  pre code {
    background-color: transparent;
    padding: 0;
  }
`;

const ProjectTemplate = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;
  const { title, date, techs, description } = frontmatter;

  return (
    <Layout location={location}>
      <Helmet title={title} />

      <StyledProjectContainer>
        <span className="breadcrumb">
          <span className="arrow">&larr;</span>
          <Link to="/projects/archive/">All Projects</Link>
        </span>

        <StyledProjectHeader>
          <h1 className="medium-heading">{title}</h1>
          <p className="subtitle">
            <span>Built at:&nbsp;</span>
            <time>
              {new Date(date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </time>
            <span>&nbsp;&mdash;&nbsp;</span>
          </p>
          <p className="subtitle">
            <span>Abstract: &nbsp;</span>
            {description}
          </p>
          <p className="subtitle">
            <span>Tech:&nbsp;</span>
            {techs &&
              techs.length > 0 &&
              techs.map((tech, i) => (
                <Link key={i} to={`/projects/techs/${kebabCase(tech)}/`} className="tag">
                  #{tech}
                </Link>
              ))}
          </p>
        </StyledProjectHeader>

        <StyledProjectContent dangerouslySetInnerHTML={{ __html: html }} />
      </StyledProjectContainer>
    </Layout>
  );
};

export default ProjectTemplate;

ProjectTemplate.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { slug: { eq: $path } }) {
      html
      frontmatter {
        title
        description
        date
        slug
        techs
      }
    }
  }
`;
